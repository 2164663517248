import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactFormComponent from "../components/pages/shared/contact-form"
import OurClientsHero from "../components/pages/shared/hero/our-clients"
import OurClientsCount from "../components/pages/our-clients/count"
import OurClientsVenodrs from "../components/pages/our-clients/vendors"
import OurClientsSlider from "../components/pages/our-clients/clients"
import OurClientsBanner from "../components/pages/our-clients/banner"
import OurClientsBlog from "../components/pages/our-clients/blog"
import Breadcrumb from '../components/controls/breadcrumbs';

const OurClientsPage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Наши клиенты', url: ''}
  ];
    return (
        <Layout page={"ourclients"} headerColor={"#6D20D2"}>
            <SEO title="Наши клиенты" description="Мы автоматизируем работу и помогаем расти компаниям из разных сфер бизнеса. Познакомьтесь с примерами внедрений и действующими клиентами Параплан CRM." keywords={[`Наши клиенты`]} url={"https://paraplancrm.ru" + props.location.pathname}/>
            <OurClientsHero />
            <Breadcrumb crumbs={ crumbs } arrowcolor={"purple"}/>
            <OurClientsCount />
            <OurClientsVenodrs />
            <OurClientsSlider />
            <OurClientsBanner />
            <OurClientsBlog />
            <ContactFormComponent color={"#F9F9FA"} />
        </Layout>
    )
}

export default OurClientsPage