import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import 'swiper/swiper-bundle.css';
import styles from './clients.module.scss';

SwiperCore.use([Navigation, EffectFade]);

function OurClientsSlider() {

    return (
        <div className={styles.clients}>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.subtitle}>Клиенты ценят</div>
                    <Swiper className={`slider ${styles.slider}`} navigation effect="fade" autoHeight={true} >
                        <SwiperSlide>
                            <div className={styles.row}>
                                <div className={`${styles.col} ${styles.left}`}>
                                    <div className={styles.title}>Дружелюбный <br /> сервис</div>
                                </div>
                                <div className={`${styles.col} ${styles.right}`}>
                                    <div className={styles.inner}>
                                        <p className={styles.text}>Действительно очень удобная и функциональная
                                            CRM-система. Система очень интуитивно понятная. Даже можно и без
                                            видео-курса (который, кстати, тоже предоставляется службой поддержки)
                                            разобраться с основным функционалом.
                                            <br />
                                            А если что-то не понятно, то Служба поддержки всегда на связи и
                                            оперативно подскажет, поможет, разъяснит. Функционал на высоте,
                                            это безусловно!
                                            <br />
                                            Все что требуется для учета, статистики, обучения и всех нужд школы -
                                            все есть!
                                            <br />
                                            И очень важно - система всегда дорабатывается и улучшается!
                                            <br />
                                            Спасибо разработчикам за удобство и продуманность системы!
                                        </p>
                                        <div className={styles.author}>
                                            Алексей Кузнецов, <br />
                                            курсы подготовки к ОГЭ и ЕГЭ
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.row}>
                                <div className={`${styles.col} ${styles.left}`}>
                                    <div className={styles.title}>Интуитивно понятный интерфейс</div>
                                </div>
                                <div className={`${styles.col} ${styles.right}`}>
                                    <div className={styles.inner}>
                                        <p className={styles.text}>
                                            Наш центр интеллектуального развития работает с системой Параплан третий год.
                                            За этот период система очень выросла, много добавлено удобных опций. Очень все оперативно, удобно, нам нравится.
                                            Есть еще пожелания к программе, но я думаю, команда Параплана их обязательно учтет и доработает в ближайшем будущем.
                                            Адекватная цена, система лояльности к клиентам - еще один плюс системы. Очень понятное и доступное приложение. Желаем команде дальнейших успехов! Мы с вами!
                                        </p>
                                        <div className={styles.author}>
                                            Анна, <br />
                                            Центр профессионального и интеллектуального развития "SELMA"
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.row}>
                                <div className={`${styles.col} ${styles.left}`}>
                                    <div className={styles.title}>Простоту использования</div>
                                </div>
                                <div className={`${styles.col} ${styles.right}`}>
                                    <div className={styles.inner}>
                                        <p className={styles.text}>
                                            Очень понравился crm paraplan, все удобно и комфортно. Педагоги быстро
                                            разобрались в работе и навели порядок в записях и учете.
                                            Уже рекомендуем другим школам и друзьям, кто имеет похожий бизнес.
                                        </p>
                                        <div className={styles.author}>
                                            Екатерина, <br />
                                            танцевальная студия “Wild Dancing Cats”
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.row}>
                                <div className={`${styles.col} ${styles.left}`}>
                                    <div className={styles.title}>Учет в едином пространстве</div>
                                </div>
                                <div className={`${styles.col} ${styles.right}`}>
                                    <div className={styles.inner}>
                                        <p className={styles.text}>
                                            Только начали использовать систему Параплан в работе своего развивающего центра, но уже почувствовали облегчение от ведения программ учета. До этой программы пытались поработать в других, но всё как-то запутано. С программой Параплан подружились сразу, конечно, есть некоторые вопросы, но это уже дело времени. И менеджер Параплана при любом вопросе на связи. Очень важно, что ещё кроме клиентов, посещения занятий в данной программе ведется и учет расходов, и можно видеть в одной программе общую картину. Надеемся на дальнейшее сотрудничество!
                                        </p>
                                        <div className={styles.author}>
                                            Ирина,<br />
                                            детский развивающий центр “Центр Знаний и Успеха”
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.row}>
                                <div className={`${styles.col} ${styles.left}`}>
                                    <div className={styles.title}>Доступ к системе с любого устройства</div>
                                </div>
                                <div className={`${styles.col} ${styles.right}`}>
                                    <div className={styles.inner}>
                                        <p className={styles.text}>
                                            Работаю с футбольными секциями, перепробовал или протестировал много crm систем, но мне сразу зашла эта программа, она полностью адаптирована с работой в мобильном телефоне. Пользуюсь 2 года, пока желания сменить программу не было.                                        </p>
                                        <div className={styles.author}>
                                            Александр,<br />
                                            футбольный клуб “Чемпион”
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.row}>
                                <div className={`${styles.col} ${styles.left}`}>
                                    <div className={styles.title}>Дружелюбную техподдержку</div>
                                </div>
                                <div className={`${styles.col} ${styles.right}`}>
                                    <div className={styles.inner}>
                                        <p className={styles.text}>
                                            У нас спортивная школа черлидинга, и мы очень долго подбирали CRM систему для себя. Пробовали очень много разных вариантов и остановились именно на Параплане. Это безумно удобная CRM для спортивной школы, где есть учет всего, что нужно, и даже больше. А ещё огромное спасибо за вашу поддержку. Я бы назвал не "поддержку", а отдел заботы о клиентах! Спасибо вам!
                                        </p>
                                        <div className={styles.author}>
                                            Эльдар,<br />
                                            спортивная школа черлидинга “Cheernika”
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.row}>
                                <div className={`${styles.col} ${styles.left}`}>
                                    <div className={styles.title}>Регулярные обновления</div>
                                </div>
                                <div className={`${styles.col} ${styles.right}`}>
                                    <div className={styles.inner}>
                                        <p className={styles.text}>
                                            Наша языковая школа пользуется системой около года. Особо хочется отметить работу службы техподдержки - на все наши вопросы мы получаем быстрые и точные ответы. Также нам нравится, что в системе регулярно происходят полезные обновления. А еще Параплан очень интуитивен, и с ним без проблем работают и сотрудники, и клиенты. Спасибо!                                            </p>
                                        <div className={styles.author}>
                                            Анна,<br />
                                            языковая школа “Impuls”
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default OurClientsSlider;