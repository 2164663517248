import React from "react"
import { openOverlay } from "../../../../utils/overlay"
import styles from "./banner.module.scss"


const OurClientsBanner = ({ name }) => (
    <div className={styles.banner}>
        <div className={styles.content}>
            <h2 className={styles.title}>
                Откройте возможности развития <br />
                вашей команды
            </h2 >
            <button className={styles.btn} onClick={() => openOverlay(name, "hero", "trial")}>
            Попробовать бесплатно
          </button>
        </div >
    </div >
)

export default OurClientsBanner
