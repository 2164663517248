import React from "react"

import styles from "./component-counters.module.scss"
import Count from "./component-counters.js"

const OurClientsCount = () => (
    <div className={styles.help}>
        <div className={`container ${styles.container}`}>
            <div className={styles.content}>
                <h2 className={styles.title}>Мы помогаем расти компаниям из разных сфер бизнеса</h2>
                <div className={styles.counters}>
                    <Count number={"16"} text={"Стран"} />
                    <Count number={"7 000"} text={"внедрений"} />
                    <Count number={"800 000"} text={"учеников"} />
                    <Count number={"3 500 000"} text={"отмеченных посещений"} />
                </div>
            </div>
        </div>
    </div >
)

export default OurClientsCount
