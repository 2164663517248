import React from "react"

import { openOverlay } from "../../../../../utils/overlay"
import styles from "./hero-our-clients-page.module.scss"
import OurClientsMap from "../../../our-clients/map"

const OurClientsHero = ({ name }) => (
  <div className={styles.hero}>
    <div className={`container ${styles.container}`}>
      <div className={styles.content}>
        <div className={styles.info}>
          <h1 className={styles.title}>Узнайте, почему <br /> нас выбирают</h1>
          <p className={styles.descr}>Наш главный стимул - улучшение бизнеса <br /> клиентов, а не количество проданных лицензий.</p>
        </div>
        <div className={styles.mapWrap}>
          <OurClientsMap />
        </div>
        <div className={`${styles.info} ${styles.infoButton}`}>
          <div className={styles.arrow}>
            Попробуйте
          </div>
          <button className={styles.btn} onClick={() => openOverlay(name, "hero", "trial")}>
            Бесплатный тест 10 дней
          </button>
        </div>
      </div>
    </div>
  </div >
)

export default OurClientsHero
