import React from "react"
import { Link } from "gatsby"
import styles from "./blog.module.scss"
import timeToRead from "../../../../images/blog/time-to-read.svg"


const OurClientsBlog = () => (
    <div className={styles.blog}>
        <div className={styles.content}>
            <h2 className={styles.title}>
                Кейсы компаний о работе с Параплан
            </h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div className={styles.img}>
                        <Link to="/blog/flexx-show-case-study" className={styles.imgLink}>
                            <img src={require('../../../../images/our-clients/blog/blog-img-2.jpg')} alt="" />
                        </Link>
                    </div>
                    <div className={styles.inner}>
                        <div className={styles.read}>
                            <i className={styles.icon}>
                                <img src={timeToRead} alt="" />
                            </i>
                            2 минуты для чтения
                        </div>
                        <div className={styles.subtitle}>
                            Кейс внедрения: Школа танцев
                        </div>
                        <div className={styles.text}>
                            Мы пообщались с Иваном, руководителем FLEXX show corporation, и задали несколько вопросов об использовании Параплан в ...
                        </div>
                        <Link to="/blog/flexx-show-case-study" className={styles.link}>
                            Подробнее
                        </Link>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.img}>
                        <Link to='/blog/business-in-the-palm-of-your-hand' className={styles.imgLink}>
                            <img src={require('../../../../images/our-clients/blog/blog-img-3.png')} alt="" />
                        </Link>
                    </div>
                    <div className={styles.inner}>
                        <div className={styles.read}>
                            <i className={styles.icon}>
                                <img src={timeToRead} alt="" />
                            </i>
                            2 минуты для чтения
                        </div>
                        <div className={styles.subtitle}>
                            “Бизнес на ладони”: создание CRM-системы для франшизы детских школ.
                        </div>
                        <div className={styles.text}>
                            Как правило, CRM-системы собирают базу клиентов, ведут бухгалтерию, отмечают учеников...
                        </div>
                        <Link to='/blog/business-in-the-palm-of-your-hand' className={styles.link}>
                            Подробнее
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.btn}>
                <Link to="/blog" className={styles.btnLink}>
                    Наш блог
                </Link>
            </div>
        </div>
    </div>
)

export default OurClientsBlog
