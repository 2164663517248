import React from "react"

import styles from "./our-clients-vendors.module.scss"
import Vendor from "./vendor"

const OurClientsVendors = () => (
    <div className={styles.vendors}>
        <div className={styles.wrap}>
            <h2 className={styles.title}>Нам доверяют</h2>
            <Vendor images={[
                {
                    src: require("../../../../images/our-clients/vendors/vendor-galaxy.svg"),
                    alt: "vendor galaxy"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-kerugi.svg"),
                    alt: "vendor kerugi"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-chempionoka.svg"),
                    alt: "vendor chempionoka"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-amega.svg"),
                    alt: "vendor amega"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-gorobo.svg"),
                    alt: "vendor gorobo"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-flexx.svg"),
                    alt: "vendor flexx"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-megakids.svg"),
                    alt: "vendor megakids"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-shkit.svg"),
                    alt: "vendor shkit"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-boom.svg"),
                    alt: "vendor boom"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-happy.svg"),
                    alt: "vendor happy"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-galaxy.svg"),
                    alt: "vendor galaxy"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-kerugi.svg"),
                    alt: "vendor kerugi"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-chempionoka.svg"),
                    alt: "vendor chempionoka"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-amega.svg"),
                    alt: "vendor amega"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-gorobo.svg"),
                    alt: "vendor gorobo"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-flexx.svg"),
                    alt: "vendor flexx"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-megakids.svg"),
                    alt: "vendor megakids"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-shkit.svg"),
                    alt: "vendor shkit"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-boom.svg"),
                    alt: "vendor boom"
                },
                {
                    src: require("../../../../images/our-clients/vendors/vendor-happy.svg"),
                    alt: "vendor happy"
                },
            ]} />
        </div>
    </div >
)

export default OurClientsVendors
