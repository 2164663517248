import React from "react"
import styles from "./component-count.module.scss"
import { Animated } from "react-animated-css"

const OurClientsCount = ({ number, text }) => (
    <Animated className={styles.count}>
        <p className={styles.countNumber}>{number}</p>
        <p className={styles.countText}>{text}</p>
    </Animated>
)

export default OurClientsCount


