import React from "react"

import styles from "./our-clients-vendor.module.scss"

const OurClientsVendor = ({ images }) => (
    <ul className={styles.items}>
        {images.map((image, index) =>
            <li key={index} className={styles.item}>
                <div className={styles.inner}>
                    <div className={styles.itemImg}>
                        <img src={image.src} alt={image.alt} />
                    </div>
                </div>
            </li >
        )}
    </ul>
)

export default OurClientsVendor
